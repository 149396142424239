/*! *****************************************************************************
Copyright (C) Microsoft. All rights reserved.
Licensed under the Apache License, Version 2.0 (the "License"); you may not use
this file except in compliance with the License. You may obtain a copy of the
License at http://www.apache.org/licenses/LICENSE-2.0

THIS CODE IS PROVIDED ON AN *AS IS* BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY IMPLIED
WARRANTIES OR CONDITIONS OF TITLE, FITNESS FOR A PARTICULAR PURPOSE,
MERCHANTABLITY OR NON-INFRINGEMENT.

See the Apache Version 2.0 License for specific language governing permissions
and limitations under the License.
***************************************************************************** */
var Reflect;
(function (Reflect) {
  "use strict";
  // Load global or shim versions of Map, Set, and WeakMap
  var functionPrototype = Object.getPrototypeOf(Function);
  var _Map = typeof Map === "function" ? Map : CreateMapPolyfill();
  var _Set = typeof Set === "function" ? Set : CreateSetPolyfill();
  var _WeakMap =
    typeof WeakMap === "function" ? WeakMap : CreateWeakMapPolyfill();
  // [[Metadata]] internal slot
  var __Metadata__ = new _WeakMap();
  /**
   * Applies a set of decorators to a property of a target object.
   * @param decorators An array of decorators.
   * @param target The target object.
   * @param targetKey (Optional) The property key to decorate.
   * @param targetDescriptor (Optional) The property descriptor for the target key
   * @remarks Decorators are applied in reverse order.
   * @example
   *
   *     class C {
   *         // property declarations are not part of ES6, though they are valid in TypeScript:
   *         // static staticProperty;
   *         // property;
   *
   *         constructor(p) { }
   *         static staticMethod(p) { }
   *         method(p) { }
   *     }
   *
   *     // constructor
   *     C = Reflect.decorate(decoratorsArray, C);
   *
   *     // property (on constructor)
   *     Reflect.decorate(decoratorsArray, C, "staticProperty");
   *
   *     // property (on prototype)
   *     Reflect.decorate(decoratorsArray, C.prototype, "property");
   *
   *     // method (on constructor)
   *     Object.defineProperty(C, "staticMethod",
   *         Reflect.decorate(decoratorsArray, C, "staticMethod",
   *             Object.getOwnPropertyDescriptor(C, "staticMethod")));
   *
   *     // method (on prototype)
   *     Object.defineProperty(C.prototype, "method",
   *         Reflect.decorate(decoratorsArray, C.prototype, "method",
   *             Object.getOwnPropertyDescriptor(C.prototype, "method")));
   *
   */
  function decorate(decorators, target, targetKey, targetDescriptor) {
    if (!IsUndefined(targetDescriptor)) {
      if (!IsArray(decorators)) {
        throw new TypeError();
      } else if (!IsObject(target)) {
        throw new TypeError();
      } else if (IsUndefined(targetKey)) {
        throw new TypeError();
      } else if (!IsObject(targetDescriptor)) {
        throw new TypeError();
      }
      targetKey = ToPropertyKey(targetKey);
      return DecoratePropertyWithDescriptor(
        decorators,
        target,
        targetKey,
        targetDescriptor
      );
    } else if (!IsUndefined(targetKey)) {
      if (!IsArray(decorators)) {
        throw new TypeError();
      } else if (!IsObject(target)) {
        throw new TypeError();
      }
      targetKey = ToPropertyKey(targetKey);
      return DecoratePropertyWithoutDescriptor(decorators, target, targetKey);
    } else {
      if (!IsArray(decorators)) {
        throw new TypeError();
      } else if (!IsConstructor(target)) {
        throw new TypeError();
      }
      return DecorateConstructor(decorators, target);
    }
  }
  Reflect.decorate = decorate;
  /**
   * A default metadata decorator factory that can be used on a class, class member, or parameter.
   * @param metadataKey The key for the metadata entry.
   * @param metadataValue The value for the metadata entry.
   * @returns A decorator function.
   * @remarks
   * If `metadataKey` is already defined for the target and target key, the
   * metadataValue for that key will be overwritten.
   * @example
   *
   *     // constructor
   *     @Reflect.metadata(key, value)
   *     class C {
   *     }
   *
   *     // property (on constructor, TypeScript only)
   *     class C {
   *         @Reflect.metadata(key, value)
   *         static staticProperty;
   *     }
   *
   *     // property (on prototype, TypeScript only)
   *     class C {
   *         @Reflect.metadata(key, value)
   *         property;
   *     }
   *
   *     // method (on constructor)
   *     class C {
   *         @Reflect.metadata(key, value)
   *         static staticMethod() { }
   *     }
   *
   *     // method (on prototype)
   *     class C {
   *         @Reflect.metadata(key, value)
   *         method() { }
   *     }
   *
   */
  function metadata(metadataKey, metadataValue) {
    function decorator(target, targetKey) {
      if (!IsUndefined(targetKey)) {
        if (!IsObject(target)) {
          throw new TypeError();
        }
        targetKey = ToPropertyKey(targetKey);
        OrdinaryDefineOwnMetadata(
          metadataKey,
          metadataValue,
          target,
          targetKey
        );
      } else {
        if (!IsConstructor(target)) {
          throw new TypeError();
        }
        OrdinaryDefineOwnMetadata(
          metadataKey,
          metadataValue,
          target,
          /*targetKey*/ undefined
        );
      }
    }
    return decorator;
  }
  Reflect.metadata = metadata;
  /**
   * Define a unique metadata entry on the target.
   * @param metadataKey A key used to store and retrieve metadata.
   * @param metadataValue A value that contains attached metadata.
   * @param target The target object on which to define metadata.
   * @param targetKey (Optional) The property key for the target.
   * @example
   *
   *     class C {
   *         // property declarations are not part of ES6, though they are valid in TypeScript:
   *         // static staticProperty;
   *         // property;
   *
   *         constructor(p) { }
   *         static staticMethod(p) { }
   *         method(p) { }
   *     }
   *
   *     // constructor
   *     Reflect.defineMetadata("custom:annotation", options, C);
   *
   *     // property (on constructor)
   *     Reflect.defineMetadata("custom:annotation", options, C, "staticProperty");
   *
   *     // property (on prototype)
   *     Reflect.defineMetadata("custom:annotation", options, C.prototype, "property");
   *
   *     // method (on constructor)
   *     Reflect.defineMetadata("custom:annotation", options, C, "staticMethod");
   *
   *     // method (on prototype)
   *     Reflect.defineMetadata("custom:annotation", options, C.prototype, "method");
   *
   *     // decorator factory as metadata-producing annotation.
   *     function MyAnnotation(options): Decorator {
   *         return (target, key?) => Reflect.defineMetadata("custom:annotation", options, target, key);
   *     }
   *
   */
  function defineMetadata(metadataKey, metadataValue, target, targetKey) {
    if (!IsObject(target)) {
      throw new TypeError();
    } else if (!IsUndefined(targetKey)) {
      targetKey = ToPropertyKey(targetKey);
    }
    return OrdinaryDefineOwnMetadata(
      metadataKey,
      metadataValue,
      target,
      targetKey
    );
  }
  Reflect.defineMetadata = defineMetadata;
  /**
   * Gets a value indicating whether the target object or its prototype chain has the provided metadata key defined.
   * @param metadataKey A key used to store and retrieve metadata.
   * @param target The target object on which the metadata is defined.
   * @param targetKey (Optional) The property key for the target.
   * @returns `true` if the metadata key was defined on the target object or its prototype chain; otherwise, `false`.
   * @example
   *
   *     class C {
   *         // property declarations are not part of ES6, though they are valid in TypeScript:
   *         // static staticProperty;
   *         // property;
   *
   *         constructor(p) { }
   *         static staticMethod(p) { }
   *         method(p) { }
   *     }
   *
   *     // constructor
   *     result = Reflect.hasMetadata("custom:annotation", C);
   *
   *     // property (on constructor)
   *     result = Reflect.hasMetadata("custom:annotation", C, "staticProperty");
   *
   *     // property (on prototype)
   *     result = Reflect.hasMetadata("custom:annotation", C.prototype, "property");
   *
   *     // method (on constructor)
   *     result = Reflect.hasMetadata("custom:annotation", C, "staticMethod");
   *
   *     // method (on prototype)
   *     result = Reflect.hasMetadata("custom:annotation", C.prototype, "method");
   *
   */
  function hasMetadata(metadataKey, target, targetKey) {
    if (!IsObject(target)) {
      throw new TypeError();
    } else if (!IsUndefined(targetKey)) {
      targetKey = ToPropertyKey(targetKey);
    }
    return OrdinaryHasMetadata(metadataKey, target, targetKey);
  }
  Reflect.hasMetadata = hasMetadata;
  /**
   * Gets a value indicating whether the target object has the provided metadata key defined.
   * @param metadataKey A key used to store and retrieve metadata.
   * @param target The target object on which the metadata is defined.
   * @param targetKey (Optional) The property key for the target.
   * @returns `true` if the metadata key was defined on the target object; otherwise, `false`.
   * @example
   *
   *     class C {
   *         // property declarations are not part of ES6, though they are valid in TypeScript:
   *         // static staticProperty;
   *         // property;
   *
   *         constructor(p) { }
   *         static staticMethod(p) { }
   *         method(p) { }
   *     }
   *
   *     // constructor
   *     result = Reflect.hasOwnMetadata("custom:annotation", C);
   *
   *     // property (on constructor)
   *     result = Reflect.hasOwnMetadata("custom:annotation", C, "staticProperty");
   *
   *     // property (on prototype)
   *     result = Reflect.hasOwnMetadata("custom:annotation", C.prototype, "property");
   *
   *     // method (on constructor)
   *     result = Reflect.hasOwnMetadata("custom:annotation", C, "staticMethod");
   *
   *     // method (on prototype)
   *     result = Reflect.hasOwnMetadata("custom:annotation", C.prototype, "method");
   *
   */
  function hasOwnMetadata(metadataKey, target, targetKey) {
    if (!IsObject(target)) {
      throw new TypeError();
    } else if (!IsUndefined(targetKey)) {
      targetKey = ToPropertyKey(targetKey);
    }
    return OrdinaryHasOwnMetadata(metadataKey, target, targetKey);
  }
  Reflect.hasOwnMetadata = hasOwnMetadata;
  /**
   * Gets the metadata value for the provided metadata key on the target object or its prototype chain.
   * @param metadataKey A key used to store and retrieve metadata.
   * @param target The target object on which the metadata is defined.
   * @param targetKey (Optional) The property key for the target.
   * @returns The metadata value for the metadata key if found; otherwise, `undefined`.
   * @example
   *
   *     class C {
   *         // property declarations are not part of ES6, though they are valid in TypeScript:
   *         // static staticProperty;
   *         // property;
   *
   *         constructor(p) { }
   *         static staticMethod(p) { }
   *         method(p) { }
   *     }
   *
   *     // constructor
   *     result = Reflect.getMetadata("custom:annotation", C);
   *
   *     // property (on constructor)
   *     result = Reflect.getMetadata("custom:annotation", C, "staticProperty");
   *
   *     // property (on prototype)
   *     result = Reflect.getMetadata("custom:annotation", C.prototype, "property");
   *
   *     // method (on constructor)
   *     result = Reflect.getMetadata("custom:annotation", C, "staticMethod");
   *
   *     // method (on prototype)
   *     result = Reflect.getMetadata("custom:annotation", C.prototype, "method");
   *
   */
  function getMetadata(metadataKey, target, targetKey) {
    if (!IsObject(target)) {
      throw new TypeError();
    } else if (!IsUndefined(targetKey)) {
      targetKey = ToPropertyKey(targetKey);
    }
    return OrdinaryGetMetadata(metadataKey, target, targetKey);
  }
  Reflect.getMetadata = getMetadata;
  /**
   * Gets the metadata value for the provided metadata key on the target object.
   * @param metadataKey A key used to store and retrieve metadata.
   * @param target The target object on which the metadata is defined.
   * @param targetKey (Optional) The property key for the target.
   * @returns The metadata value for the metadata key if found; otherwise, `undefined`.
   * @example
   *
   *     class C {
   *         // property declarations are not part of ES6, though they are valid in TypeScript:
   *         // static staticProperty;
   *         // property;
   *
   *         constructor(p) { }
   *         static staticMethod(p) { }
   *         method(p) { }
   *     }
   *
   *     // constructor
   *     result = Reflect.getOwnMetadata("custom:annotation", C);
   *
   *     // property (on constructor)
   *     result = Reflect.getOwnMetadata("custom:annotation", C, "staticProperty");
   *
   *     // property (on prototype)
   *     result = Reflect.getOwnMetadata("custom:annotation", C.prototype, "property");
   *
   *     // method (on constructor)
   *     result = Reflect.getOwnMetadata("custom:annotation", C, "staticMethod");
   *
   *     // method (on prototype)
   *     result = Reflect.getOwnMetadata("custom:annotation", C.prototype, "method");
   *
   */
  function getOwnMetadata(metadataKey, target, targetKey) {
    if (!IsObject(target)) {
      throw new TypeError();
    } else if (!IsUndefined(targetKey)) {
      targetKey = ToPropertyKey(targetKey);
    }
    return OrdinaryGetOwnMetadata(metadataKey, target, targetKey);
  }
  Reflect.getOwnMetadata = getOwnMetadata;
  /**
   * Gets the metadata keys defined on the target object or its prototype chain.
   * @param target The target object on which the metadata is defined.
   * @param targetKey (Optional) The property key for the target.
   * @returns An array of unique metadata keys.
   * @example
   *
   *     class C {
   *         // property declarations are not part of ES6, though they are valid in TypeScript:
   *         // static staticProperty;
   *         // property;
   *
   *         constructor(p) { }
   *         static staticMethod(p) { }
   *         method(p) { }
   *     }
   *
   *     // constructor
   *     result = Reflect.getMetadataKeys(C);
   *
   *     // property (on constructor)
   *     result = Reflect.getMetadataKeys(C, "staticProperty");
   *
   *     // property (on prototype)
   *     result = Reflect.getMetadataKeys(C.prototype, "property");
   *
   *     // method (on constructor)
   *     result = Reflect.getMetadataKeys(C, "staticMethod");
   *
   *     // method (on prototype)
   *     result = Reflect.getMetadataKeys(C.prototype, "method");
   *
   */
  function getMetadataKeys(target, targetKey) {
    if (!IsObject(target)) {
      throw new TypeError();
    } else if (!IsUndefined(targetKey)) {
      targetKey = ToPropertyKey(targetKey);
    }
    return OrdinaryMetadataKeys(target, targetKey);
  }
  Reflect.getMetadataKeys = getMetadataKeys;
  /**
   * Gets the unique metadata keys defined on the target object.
   * @param target The target object on which the metadata is defined.
   * @param targetKey (Optional) The property key for the target.
   * @returns An array of unique metadata keys.
   * @example
   *
   *     class C {
   *         // property declarations are not part of ES6, though they are valid in TypeScript:
   *         // static staticProperty;
   *         // property;
   *
   *         constructor(p) { }
   *         static staticMethod(p) { }
   *         method(p) { }
   *     }
   *
   *     // constructor
   *     result = Reflect.getOwnMetadataKeys(C);
   *
   *     // property (on constructor)
   *     result = Reflect.getOwnMetadataKeys(C, "staticProperty");
   *
   *     // property (on prototype)
   *     result = Reflect.getOwnMetadataKeys(C.prototype, "property");
   *
   *     // method (on constructor)
   *     result = Reflect.getOwnMetadataKeys(C, "staticMethod");
   *
   *     // method (on prototype)
   *     result = Reflect.getOwnMetadataKeys(C.prototype, "method");
   *
   */
  function getOwnMetadataKeys(target, targetKey) {
    if (!IsObject(target)) {
      throw new TypeError();
    } else if (!IsUndefined(targetKey)) {
      targetKey = ToPropertyKey(targetKey);
    }
    return OrdinaryOwnMetadataKeys(target, targetKey);
  }
  Reflect.getOwnMetadataKeys = getOwnMetadataKeys;
  /**
   * Deletes the metadata entry from the target object with the provided key.
   * @param metadataKey A key used to store and retrieve metadata.
   * @param target The target object on which the metadata is defined.
   * @param targetKey (Optional) The property key for the target.
   * @returns `true` if the metadata entry was found and deleted; otherwise, false.
   * @example
   *
   *     class C {
   *         // property declarations are not part of ES6, though they are valid in TypeScript:
   *         // static staticProperty;
   *         // property;
   *
   *         constructor(p) { }
   *         static staticMethod(p) { }
   *         method(p) { }
   *     }
   *
   *     // constructor
   *     result = Reflect.deleteMetadata("custom:annotation", C);
   *
   *     // property (on constructor)
   *     result = Reflect.deleteMetadata("custom:annotation", C, "staticProperty");
   *
   *     // property (on prototype)
   *     result = Reflect.deleteMetadata("custom:annotation", C.prototype, "property");
   *
   *     // method (on constructor)
   *     result = Reflect.deleteMetadata("custom:annotation", C, "staticMethod");
   *
   *     // method (on prototype)
   *     result = Reflect.deleteMetadata("custom:annotation", C.prototype, "method");
   *
   */
  function deleteMetadata(metadataKey, target, targetKey) {
    if (!IsObject(target)) {
      throw new TypeError();
    } else if (!IsUndefined(targetKey)) {
      targetKey = ToPropertyKey(targetKey);
    }
    // https://github.com/jonathandturner/decorators/blob/master/specs/metadata.md#deletemetadata-metadatakey-p-
    var metadataMap = GetOrCreateMetadataMap(
      target,
      targetKey,
      /*create*/ false
    );
    if (IsUndefined(metadataMap)) {
      return false;
    }
    if (!metadataMap.delete(metadataKey)) {
      return false;
    }
    if (metadataMap.size > 0) {
      return true;
    }
    var targetMetadata = __Metadata__.get(target);
    targetMetadata.delete(targetKey);
    if (targetMetadata.size > 0) {
      return true;
    }
    __Metadata__.delete(target);
    return true;
  }
  Reflect.deleteMetadata = deleteMetadata;
  function DecorateConstructor(decorators, target) {
    for (var i = decorators.length - 1; i >= 0; --i) {
      var decorator = decorators[i];
      var decorated = decorator(target);
      if (!IsUndefined(decorated)) {
        if (!IsConstructor(decorated)) {
          throw new TypeError();
        }
        target = decorated;
      }
    }
    return target;
  }
  function DecoratePropertyWithDescriptor(
    decorators,
    target,
    propertyKey,
    descriptor
  ) {
    for (var i = decorators.length - 1; i >= 0; --i) {
      var decorator = decorators[i];
      var decorated = decorator(target, propertyKey, descriptor);
      if (!IsUndefined(decorated)) {
        if (!IsObject(decorated)) {
          throw new TypeError();
        }
        descriptor = decorated;
      }
    }
    return descriptor;
  }
  function DecoratePropertyWithoutDescriptor(decorators, target, propertyKey) {
    for (var i = decorators.length - 1; i >= 0; --i) {
      var decorator = decorators[i];
      decorator(target, propertyKey);
    }
  }
  // https://github.com/jonathandturner/decorators/blob/master/specs/metadata.md#getorcreatemetadatamap--o-p-create-
  function GetOrCreateMetadataMap(target, targetKey, create) {
    var targetMetadata = __Metadata__.get(target);
    if (!targetMetadata) {
      if (!create) {
        return undefined;
      }
      targetMetadata = new _Map();
      __Metadata__.set(target, targetMetadata);
    }
    var keyMetadata = targetMetadata.get(targetKey);
    if (!keyMetadata) {
      if (!create) {
        return undefined;
      }
      keyMetadata = new _Map();
      targetMetadata.set(targetKey, keyMetadata);
    }
    return keyMetadata;
  }
  // https://github.com/jonathandturner/decorators/blob/master/specs/metadata.md#ordinaryhasmetadata--metadatakey-o-p-
  function OrdinaryHasMetadata(MetadataKey, O, P) {
    var hasOwn = OrdinaryHasOwnMetadata(MetadataKey, O, P);
    if (hasOwn) {
      return true;
    }
    var parent = GetPrototypeOf(O);
    if (parent !== null) {
      return OrdinaryHasMetadata(MetadataKey, parent, P);
    }
    return false;
  }
  // https://github.com/jonathandturner/decorators/blob/master/specs/metadata.md#ordinaryhasownmetadata--metadatakey-o-p-
  function OrdinaryHasOwnMetadata(MetadataKey, O, P) {
    var metadataMap = GetOrCreateMetadataMap(O, P, /*create*/ false);
    if (metadataMap === undefined) {
      return false;
    }
    return Boolean(metadataMap.has(MetadataKey));
  }
  // https://github.com/jonathandturner/decorators/blob/master/specs/metadata.md#ordinarygetmetadata--metadatakey-o-p-
  function OrdinaryGetMetadata(MetadataKey, O, P) {
    var hasOwn = OrdinaryHasOwnMetadata(MetadataKey, O, P);
    if (hasOwn) {
      return OrdinaryGetOwnMetadata(MetadataKey, O, P);
    }
    var parent = GetPrototypeOf(O);
    if (parent !== null) {
      return OrdinaryGetMetadata(MetadataKey, parent, P);
    }
    return undefined;
  }
  // https://github.com/jonathandturner/decorators/blob/master/specs/metadata.md#ordinarygetownmetadata--metadatakey-o-p-
  function OrdinaryGetOwnMetadata(MetadataKey, O, P) {
    var metadataMap = GetOrCreateMetadataMap(O, P, /*create*/ false);
    if (metadataMap === undefined) {
      return undefined;
    }
    return metadataMap.get(MetadataKey);
  }
  // https://github.com/jonathandturner/decorators/blob/master/specs/metadata.md#ordinarydefineownmetadata--metadatakey-metadatavalue-o-p-
  function OrdinaryDefineOwnMetadata(MetadataKey, MetadataValue, O, P) {
    var metadataMap = GetOrCreateMetadataMap(O, P, /*create*/ true);
    metadataMap.set(MetadataKey, MetadataValue);
  }
  // https://github.com/jonathandturner/decorators/blob/master/specs/metadata.md#ordinarymetadatakeys--o-p-
  function OrdinaryMetadataKeys(O, P) {
    var ownKeys = OrdinaryOwnMetadataKeys(O, P);
    var parent = GetPrototypeOf(O);
    if (parent === null) {
      return ownKeys;
    }
    var parentKeys = OrdinaryMetadataKeys(parent, P);
    if (parentKeys.length <= 0) {
      return ownKeys;
    }
    if (ownKeys.length <= 0) {
      return parentKeys;
    }
    var set = new _Set();
    var keys = [];
    for (var _i = 0; _i < ownKeys.length; _i++) {
      var key = ownKeys[_i];
      var hasKey = set.has(key);
      if (!hasKey) {
        set.add(key);
        keys.push(key);
      }
    }
    for (var _a = 0; _a < parentKeys.length; _a++) {
      var key = parentKeys[_a];
      var hasKey = set.has(key);
      if (!hasKey) {
        set.add(key);
        keys.push(key);
      }
    }
    return keys;
  }
  // https://github.com/jonathandturner/decorators/blob/master/specs/metadata.md#ordinaryownmetadatakeys--o-p-
  function OrdinaryOwnMetadataKeys(target, targetKey) {
    var metadataMap = GetOrCreateMetadataMap(
      target,
      targetKey,
      /*create*/ false
    );
    var keys = [];
    if (metadataMap) {
      metadataMap.forEach(function (_, key) {
        return keys.push(key);
      });
    }
    return keys;
  }
  // https://people.mozilla.org/~jorendorff/es6-draft.html#sec-ecmascript-language-types-undefined-type
  function IsUndefined(x) {
    return x === undefined;
  }
  // https://people.mozilla.org/~jorendorff/es6-draft.html#sec-isarray
  function IsArray(x) {
    return Array.isArray(x);
  }
  // https://people.mozilla.org/~jorendorff/es6-draft.html#sec-object-type
  function IsObject(x) {
    return typeof x === "object" ? x !== null : typeof x === "function";
  }
  // https://people.mozilla.org/~jorendorff/es6-draft.html#sec-isconstructor
  function IsConstructor(x) {
    return typeof x === "function";
  }
  // https://people.mozilla.org/~jorendorff/es6-draft.html#sec-ecmascript-language-types-symbol-type
  function IsSymbol(x) {
    return typeof x === "symbol";
  }
  // https://people.mozilla.org/~jorendorff/es6-draft.html#sec-topropertykey
  function ToPropertyKey(value) {
    if (IsSymbol(value)) {
      return value;
    }
    return String(value);
  }
  function GetPrototypeOf(O) {
    var proto = Object.getPrototypeOf(O);
    if (typeof O !== "function" || O === functionPrototype) {
      return proto;
    }
    // TypeScript doesn't set __proto__ in ES5, as it's non-standard.
    // Try to determine the superclass constructor. Compatible implementations
    // must either set __proto__ on a subclass constructor to the superclass constructor,
    // or ensure each class has a valid `constructor` property on its prototype that
    // points back to the constructor.
    // If this is not the same as Function.[[Prototype]], then this is definately inherited.
    // This is the case when in ES6 or when using __proto__ in a compatible browser.
    if (proto !== functionPrototype) {
      return proto;
    }
    // If the super prototype is Object.prototype, null, or undefined, then we cannot determine the heritage.
    var prototype = O.prototype;
    var prototypeProto = Object.getPrototypeOf(prototype);
    if (prototypeProto == null || prototypeProto === Object.prototype) {
      return proto;
    }
    // if the constructor was not a function, then we cannot determine the heritage.
    var constructor = prototypeProto.constructor;
    if (typeof constructor !== "function") {
      return proto;
    }
    // if we have some kind of self-reference, then we cannot determine the heritage.
    if (constructor === O) {
      return proto;
    }
    // we have a pretty good guess at the heritage.
    return constructor;
  }
  // naive Map shim
  function CreateMapPolyfill() {
    var cacheSentinel = {};
    function Map() {
      this._keys = [];
      this._values = [];
      this._cache = cacheSentinel;
    }
    Map.prototype = {
      get size() {
        return this._keys.length;
      },
      has: function (key) {
        if (key === this._cache) {
          return true;
        }
        if (this._find(key) >= 0) {
          this._cache = key;
          return true;
        }
        return false;
      },
      get: function (key) {
        var index = this._find(key);
        if (index >= 0) {
          this._cache = key;
          return this._values[index];
        }
        return undefined;
      },
      set: function (key, value) {
        this.delete(key);
        this._keys.push(key);
        this._values.push(value);
        this._cache = key;
        return this;
      },
      delete: function (key) {
        var index = this._find(key);
        if (index >= 0) {
          this._keys.splice(index, 1);
          this._values.splice(index, 1);
          this._cache = cacheSentinel;
          return true;
        }
        return false;
      },
      clear: function () {
        this._keys.length = 0;
        this._values.length = 0;
        this._cache = cacheSentinel;
      },
      forEach: function (callback, thisArg) {
        var size = this.size;
        for (var i = 0; i < size; ++i) {
          var key = this._keys[i];
          var value = this._values[i];
          this._cache = key;
          callback.call(this, value, key, this);
        }
      },
      _find: function (key) {
        var keys = this._keys;
        var size = keys.length;
        for (var i = 0; i < size; ++i) {
          if (keys[i] === key) {
            return i;
          }
        }
        return -1;
      },
    };
    return Map;
  }
  // naive Set shim
  function CreateSetPolyfill() {
    var cacheSentinel = {};
    function Set() {
      this._map = new _Map();
    }
    Set.prototype = {
      get size() {
        return this._map.length;
      },
      has: function (value) {
        return this._map.has(value);
      },
      add: function (value) {
        this._map.set(value, value);
        return this;
      },
      delete: function (value) {
        return this._map.delete(value);
      },
      clear: function () {
        this._map.clear();
      },
      forEach: function (callback, thisArg) {
        this._map.forEach(callback, thisArg);
      },
    };
    return Set;
  }
  // naive WeakMap shim
  function CreateWeakMapPolyfill() {
    var UUID_SIZE = 16;
    var isNode =
      typeof global !== "undefined" &&
      Object.prototype.toString.call(global.process) === "[object process]";
    var nodeCrypto = isNode && require("crypto");
    var hasOwn = Object.prototype.hasOwnProperty;
    var keys = {};
    var rootKey = CreateUniqueKey();
    function WeakMap() {
      this._key = CreateUniqueKey();
    }
    WeakMap.prototype = {
      has: function (target) {
        var table = GetOrCreateWeakMapTable(target, /*create*/ false);
        if (table) {
          return this._key in table;
        }
        return false;
      },
      get: function (target) {
        var table = GetOrCreateWeakMapTable(target, /*create*/ false);
        if (table) {
          return table[this._key];
        }
        return undefined;
      },
      set: function (target, value) {
        var table = GetOrCreateWeakMapTable(target, /*create*/ true);
        table[this._key] = value;
        return this;
      },
      delete: function (target) {
        var table = GetOrCreateWeakMapTable(target, /*create*/ false);
        if (table && this._key in table) {
          return delete table[this._key];
        }
        return false;
      },
      clear: function () {
        // NOTE: not a real clear, just makes the previous data unreachable
        this._key = CreateUniqueKey();
      },
    };
    function FillRandomBytes(buffer, size) {
      for (var i = 0; i < size; ++i) {
        buffer[i] = (Math.random() * 255) | 0;
      }
    }
    function GenRandomBytes(size) {
      if (nodeCrypto) {
        var data = nodeCrypto.randomBytes(size);
        return data;
      } else if (typeof Uint8Array === "function") {
        var data = new Uint8Array(size);
        if (typeof crypto !== "undefined") {
          crypto.getRandomValues(data);
        } else if (typeof msCrypto !== "undefined") {
          msCrypto.getRandomValues(data);
        } else {
          FillRandomBytes(data, size);
        }
        return data;
      } else {
        var data = new Array(size);
        FillRandomBytes(data, size);
        return data;
      }
    }
    function CreateUUID() {
      var data = GenRandomBytes(UUID_SIZE);
      // mark as random - RFC 4122 § 4.4
      data[6] = (data[6] & 0x4f) | 0x40;
      data[8] = (data[8] & 0xbf) | 0x80;
      var result = "";
      for (var offset = 0; offset < UUID_SIZE; ++offset) {
        var byte = data[offset];
        if (offset === 4 || offset === 6 || offset === 8) {
          result += "-";
        }
        if (byte < 16) {
          result += "0";
        }
        result += byte.toString(16).toLowerCase();
      }
      return result;
    }
    function CreateUniqueKey() {
      var key;
      do {
        key = "@@WeakMap@@" + CreateUUID();
      } while (hasOwn.call(keys, key));
      keys[key] = true;
      return key;
    }
    function GetOrCreateWeakMapTable(target, create) {
      if (!hasOwn.call(target, rootKey)) {
        if (!create) {
          return undefined;
        }
        Object.defineProperty(target, rootKey, { value: Object.create(null) });
      }
      return target[rootKey];
    }
    return WeakMap;
  }
  // hook global Reflect
  (function (__global) {
    if (typeof __global.Reflect !== "undefined") {
      if (__global.Reflect !== Reflect) {
        for (var p in Reflect) {
          __global.Reflect[p] = Reflect[p];
        }
      }
    } else {
      __global.Reflect = Reflect;
    }
  })(
    typeof window !== "undefined"
      ? window
      : typeof WorkerGlobalScope !== "undefined"
      ? self
      : typeof global !== "undefined"
      ? global
      : Function("return this;")()
  );
})(Reflect || (Reflect = {}));
